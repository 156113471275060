import React, { useState } from 'react';

function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 发送请求到后端，请求验证码
    // 更新界面显示接收到的验证码
  };

  return (
      <div>
        <h1>接码功能</h1>
        <form onSubmit={handleSubmit}>
          <label>
            手机号码:
            <input type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
          </label>
          <br />
          <label>
            验证码:
            <input type="text" value={verificationCode} onChange={handleVerificationCodeChange} />
          </label>
          <br />
          <button type="submit">提交</button>
        </form>
        <div>
          <h2>接收到的验证码</h2>
          <p>{verificationCode}</p>
        </div>
      </div>
  );
}

export default App;
